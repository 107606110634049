import React from 'react';
import { Link } from 'react-router-dom';


const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="footer-widget">
              <h3>Quick Links</h3>
              <ul>
                <li><i className="fa fa-chevron-right" aria-hidden="true"></i> <Link to="/career">Carrer</Link></li>
                <li><i className="fa fa-chevron-right" aria-hidden="true"></i> <Link to="/how-we-works">How we Work?</Link></li>

                <li><i className="fa fa-chevron-right" aria-hidden="true"></i> <Link to="/kumbhaatech-affordabe-software-development-chennai">Blogs</Link></li>
                <li><i className="fa fa-chevron-right" aria-hidden="true"></i> <Link to="#">privacy policy</Link></li>
                <li><i className="fa fa-chevron-right" aria-hidden="true"></i> <Link to="#">Terms and Conditions</Link></li>
                <li><i className="fa fa-chevron-right" aria-hidden="true"></i> <Link to="#">Frequently Asked Questions</Link></li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="footer-widget">
              <h3>Quick Links</h3>
              <ul>
                <li><i className="fa fa-chevron-right" aria-hidden="true"></i> <Link to="/">Home</Link></li>
                <li><i className="fa fa-chevron-right" aria-hidden="true"></i> <Link to="/about-us">About Us</Link></li>
                <li><i className="fa fa-chevron-right" aria-hidden="true"></i> <Link to="/services">Services</Link></li>
                <li><i className="fa fa-chevron-right" aria-hidden="true"></i> <Link to="/kumbhaatech-affordabe-software-development-chennai">Projects</Link></li>

                <li><i className="fa fa-chevron-right" aria-hidden="true"></i> <Link to="/kumbhatech-affordable-website-design-chennai">Contact Us</Link></li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="footer-widget">
              <h3> Services</h3>
              <ul>
                <li><i className="fa fa-chevron-right" aria-hidden="true"></i> <Link to="/affordable-website-design-services-chennai">Web Development</Link></li>
                <li><i className="fa fa-chevron-right" aria-hidden="true"></i> <Link to="/cheap-PHP-MYSQL-software-customization-chennai">Software Development</Link></li>
                <li><i className="fa fa-chevron-right" aria-hidden="true"></i> <Link to="/cheap-content-writing-seo-chennai">Content Writing</Link></li>
                <li><i className="fa fa-chevron-right" aria-hidden="true"></i> <Link to="/affordable-strategic-digital-marketing-chennai">Digital Marketing</Link></li>
                <li><i className="fa fa-chevron-right" aria-hidden="true"></i> <Link to="/cheap-overseas-sop-writing-college-universities-visa-chennai">SOP Writing</Link></li>
                <li><i className="fa fa-chevron-right" aria-hidden="true"></i> <Link to="/cheap-domain-registration-chennai">Domain Regitration</Link></li>
                <li><i className="fa fa-chevron-right" aria-hidden="true"></i> <Link to="/web-hosting-services">Web Hosting</Link></li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="footer-widget">
              <h3>Contact Us</h3>
              <p>Mylapore chennai -600004</p>
              <p>E-mail: support@kumbhatech.com</p>
              <p>Talk To Expert: +91- 8825542056</p>
              <br />
              <ul className="social-icons">
                <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                <li><a href="#"><i className="fa fa-instagram"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>
          © 2017-{currentYear} <strong>kumbhaatech Solution.</strong> All Rights Reserved | kumbhaatech Solution name and logo are registered by kumbhaatech Solution.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
{/* <a href="https://www.flaticon.com/free-animated-icons/refresh" title="refresh animated icons">Refresh animated icons created by Freepik - Flaticon</a> */ }
{/* <a href="https://www.flaticon.com/free-animated-icons/development" title="development animated icons">Development animated icons created by Freepik - Flaticon cost</a> */ }
{/* <a href="https://www.flaticon.com/free-animated-icons/quality" title="quality animated icons">Quality animated icons created by Freepik - Flaticon quality</a>
<a href="https://www.flaticon.com/free-animated-icons/computer" title="computer animated icons">Computer animated icons created by Freepik - Flaticon voting</a>
<a href="https://www.flaticon.com/free-animated-icons/reunion" title="reunion animated icons">Reunion animated icons created by Freepik - Flaticon team</a> */}
{/* <a href="https://www.flaticon.com/free-animated-icons/anti-terrorism-day" title="anti terrorism day animated icons">Anti terrorism day animated icons created by Freepik - Flaticon space</a>
<a href="https://www.flaticon.com/free-animated-icons/full-screen" title="full screen animated icons">Full screen animated icons created by Freepik - Flaticon scal</a>
<a href="https://www.flaticon.com/free-animated-icons/customer-service" title="customer service animated icons">Customer service animated icons created by Freepik - Flaticon support</a> */}