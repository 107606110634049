import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [submenuOpen, setSubmenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    const toggleMenu = () => {
        setMenuOpen((prevMenuOpen) => !prevMenuOpen);
    };

    const toggleSubmenu = () => {
        setSubmenuOpen((prevSubmenuOpen) => !prevSubmenuOpen);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerWidth > 1000) {
                if (window.scrollY > 50) {
                    setIsScrolled(true);
                } else {
                    setIsScrolled(false);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Fragment>
            <div className="sub-header">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-sm-8">
                            <div className="left-content">
                                <ul>
                                    <li><i className='fa fa-map-marker'></i>Mylapore , Chennai.</li>
                                    <li><i className='fa fa-envelope'></i>support@kumbhatech.com</li>
                                    <li>Talk TO Expert:+91-8825542056</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-4">
                            <div className="right-icons">
                                <ul>
                                    <li><Link to=' '><i className="fa fa-facebook"></i></Link></li>
                                    <li><Link to=' '><i className="fa fa-twitter"></i></Link></li>
                                    <li><Link to=' '><i className="fa fa-behance"></i></Link></li>
                                    <li><Link to=' '><i className="fa fa-linkedin"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <header className={`header-area header-sticky ${menuOpen ? 'menu-open' : ''} ${isScrolled ? 'scrolled' : ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <nav className="main-nav">
                                <Link to="/" className="logo">
                                    <img src="assets/images/logo.jpg" alt="Logo" className={isScrolled ? 'scrolled-logo' : ''} />
                                </Link>
                                <ul className={`nav ${menuOpen ? 'open' : ''}`}>
                                    <li className="scroll-to-section"><Link to="/" className="active">Home</Link></li>
                                    <li><Link to="/services">Services</Link></li>
                                    <li className={`has-sub ${submenuOpen ? 'open' : ''}`} onClick={toggleSubmenu}>
                                        <Link to="#">Company</Link>
                                        <ul className="sub-menu">
                                            <li><Link to="/about-us">About Us</Link></li>
                                            <li><Link to="/career">Career</Link></li>
                                            <li><Link to="/how-we-works">How We Work</Link></li>
                                        </ul>
                                    </li>
                                    <li className="scroll-to-section"><Link to="/kumbhaatech-affordabe-software-development-chennai">Projects</Link></li>
                                    <li className="scroll-to-section"><Link to="/kumbhaatech-affordabe-software-development-chennai">Blogs</Link></li>
                                    <li className="scroll-to-section"><Link to="/kumbhatech-affordable-website-design-chennai">Contact Us</Link></li>
                                </ul>
                                <a className="menu-trigger" onClick={toggleMenu}>
                                    <span>Menu</span>
                                </a>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        </Fragment>
    );
};

export default Header;
