import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './BannerSlider.css';

const slides = [
  {
    id: 1,

    title: 'Bold and Inspiring',
    subtitle: 'Transforming Ideas into Digital Masterpieces',
    description: 'Elevate your brand with innovative design and seamless user experiences.',
    linkText: 'Discover Our Work!',
    indicatorText: 'Digital Masterpieces',

  },
  {
    id: 2,

    title: 'Sleek and Professional',
    subtitle: 'Crafting Unique Digital Journeys',
    description: 'Exceptional website design tailored to your brand’s vision.',
    linkText: 'Get Started Today!',
    indicatorText: 'Digital Journeys',
  },
  {
    id: 3,

    title: 'Modern and Dynamic',
    subtitle: 'Unleash Creativity with Cutting-Edge Web Design',
    description: 'Building stunning websites that captivate and convert.',
    linkText: 'View Our Portfolio!',
    indicatorText: 'Cutting-Edge',
  },
  {
    id: 4,

    title: 'Artistic and Vibrant',
    subtitle: 'Where Creativity Meets Technology',
    description: 'Innovative designs that bring your brand to life.',
    linkText: 'See Our Creations!',
    indicatorText: 'brand to life',
  },
  {
    id: 5,

    title: 'Minimalist and Elegant',
    subtitle: 'Design That Speaks Volumes',
    description: 'Sophisticated web solutions for the modern business.',
    linkText: 'Learn More!',
    indicatorText: 'modern business',
  },
];
const BannerSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [autoSlide, setAutoSlide] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    let slideInterval = null;
    if (autoSlide) {
      slideInterval = setInterval(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
      }, 5000);
    }
    return () => clearInterval(slideInterval);
  }, [autoSlide]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleIndicatorClick = (index) => {
    setCurrentSlide(index);
    setAutoSlide(false);
  };

  const handleToggleAutoSlide = () => {
    setAutoSlide((prevAutoSlide) => !prevAutoSlide);
  };

  return (
    <section className="section main-banner" id="top" data-section="section1">
      < div className='bg-video '>
        <img src="assets/banners/home-banner-01.webp" />
      </div>
      <div className="images-overlay header-text">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="caption">
                {/*  <h5>{slides[currentSlide].title}</h5> */}
                <h2>{slides[currentSlide].subtitle}</h2>

                <p>{slides[currentSlide].description}</p>
                <div className="">
                  <div className="banner-button">
                    <Link to='/kumbhatech-affordable-website-design-chennai'>{slides[currentSlide].linkText}</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="indicator-row">
            {slides.map((slide, index) => (
              <div
                key={slide.id}
                className={`indicator ${index === currentSlide ? 'active' : ''}`}
                onClick={() => handleIndicatorClick(index)}
              >
                {screenWidth > 992 ? slide.indicatorText : <span className="dot">■</span>}
              </div>
            ))}
            <button className="toggle-auto-slide" onClick={handleToggleAutoSlide}>
              {autoSlide ? <i className="fa fa-pause" aria-hidden="true"></i> : <i className="fa fa-play" aria-hidden="true"></i>}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerSlider;