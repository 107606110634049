import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import { Link } from 'react-router-dom';

const Services = () => {
  useEffect(() => {
    document.title = 'Affordable Services At KumbhaaTech Solutions';
    return () => {
      document.title = 'Leading Software Company - KumbhaaTech Solutions';
    };
  }, []);

  const servicesData = [
    {
      id: 1,
      img: 'assets/images/KT-Webdevelopment.png',
      stimg: 'assets/images/KT-RD-WEB.gif',
      title: 'Quality Website Design & Development',
      description: 'Create stunning, responsive websites that deliver an excellent user experience and reflect your brand\'s identity',
      link: '/affordable-website-design-services-chennai',
    },
    {
      id: 2,
      img: 'assets/images/KT-software-rd.png',
      stimg: 'assets/images/KT-software.gif',
      title: 'Professional Software Services',
      description: 'Develop custom software solutions tailored to your business needs, enhancing efficiency and productivity',
      link: '/cheap-PHP-MYSQL-software-customization-chennai',
    },
    {
      id: 3,
      img: 'assets/images/KT-content.png',
      stimg: 'assets/images/KT-content-rd.gif',
      title: 'Expert Content Writing',
      description: 'Deliver engaging, SEO-optimized content that captivates your audience and boosts your search engine rankings.',
      link: 'cheap-content-writing-seo-chennai',
    },
    {
      id: 4,
      img: 'assets/images/KT-digitalMaketing-red.png',
      stimg: 'assets/images/KT-digitalMaketing.gif',
      title: 'Strategic Digital Marketing',
      description: 'Develop comprehensive digital marketing strategies, including social media marketing and email campaigns, to drive traffic and increase conversions',
      link: '/affordable-strategic-digital-marketing-chennai',
    },
    {
      id: 5,
      img: 'assets/images/KT-domain-red.png',
      stimg: 'assets/images/KT-domain.png',
      title: 'Domain Registration',
      description: 'Secure your online presence with hassle-free domain registration services.',
      link: '/cheap-domain-registration-chennai',
    },
    {
      id: 7,
      img: 'assets/images/KT-seo-red.png',
      stimg: 'assets/images/KT-seo.gif',
      title: 'Effective SEO',
      description: 'Improve your website\'s visibility on search engines with our proven SEO techniques.',
      link: '/affordable-seo-site-ranking-service-chennai',
    },
    {
      id: 6,
      img: 'assets/images/KT-domain-red.png',
      stimg: 'assets/images/KT-domain.png',
      title: 'Web Hosting',
      description: 'Discover our affordable website hosting solutions designed to meet your needs without breaking your budget.',
      link: '/web-hosting-services',
    },

    {
      id: 9,
      img: 'assets/images/telemarketing-r.png',
      stimg: 'assets/images/telemarketing-b.gif',
      title: 'Telemarketing & Telesales ',
      description: 'Boost your sales and connect with customers directly through our expert telemarketing and telesales services.  ',
      link: '/looking-for-affordable-telemarketing-service-chennai',

    },
    {
      id: 8,
      img: 'assets/images/KT-academic-writing-red.png',
      stimg: 'assets/images/KT-academic-writing-red.gif',
      title: 'Comprehensive Academic Writing',
      description: 'Receive expertly crafted academic papers and research content tailored to your requirements.',
      link: '/affordable-MBA-academic-writing-service-affordable-chennai',
    },



  ];

  return (
    <>
      <Header />
      <div className="custom-banner">
        <div className="service-banner-content">
          <h1>
            Best Custom Software Development Company <span>for Digital Success</span>
          </h1>
          <p>
            Affordable Services for Your Business Needs. From website development, software development, and digital marketing to SEO, SOP, and assignment writing, we’ve got you covered.
          </p>
          <br />
          <Link to="/kumbhatech-affordable-website-design-chennai" className="abt-btn">Get Started Today</Link>
        </div>
      </div>
      <section className="key-module-bg" style={{ marginTop: '10px' }}>
        <div className="container">
          <div className="features-courseProject">
            <div className="row">
              <div className="col-lg-12">
                <h2>Affordable Services</h2>
                <br />
                <h5>Get started today—contact us for a free consultation!</h5>
              </div>
              <div className="key-modules-container">
                {servicesData.map(service => (
                  <div key={service.id} className="key-module">
                    <div className="key-image-container">
                      <img
                        src={service.img}
                        alt={`Static image for ${service.title}`}
                        className="static-image"
                        loading="lazy"
                        decoding="async"
                      />
                      <img
                        src={service.stimg}
                        alt={`Animated image for ${service.title}`}
                        className="animated-image"
                        loading="lazy"
                        decoding="async"
                      />
                    </div>
                    <h5>{service.title}</h5>
                    <p>{service.description}</p>
                    <br />
                    <Link to={service.link} className="explore-button">
                      <span className="explore-texts">Explore More</span>
                      <span className="arrows">&rarr;</span>
                    </Link>
                  </div>
                ))}
              </div>
              <div className="col-lg-4">
                <div className="key-module">
                  <div className="key-image-container">
                    <img
                      src="assets/images/KT-personalised-sop-red.png"
                      alt="Personalized SOP Writing"
                      className="static-image"
                      loading="lazy"
                      decoding="async"
                    />
                    <img
                      src="assets/images/KT-personalised-sop.gif"
                      alt="personalised-sop Animated"
                      className="animated-image"
                      loading="lazy"
                      decoding="async"
                    />
                  </div>
                  <h5>Personalized SOP Writing</h5>
                  <p>Get customized Statements of Purpose (SOPs) that enhance your university applications.</p>
                  <br />
                  <Link to="/cheap-overseas-sop-writing-college-universities-visa-chennai" className="explore-button">
                    <span className="explore-texts">Explore More</span>
                    <span className="arrows">&rarr;</span>
                  </Link>

                </div>
              </div>
              <div className="col-lg-4">
                <div className="key-module">
                  <div className="key-image-container">
                    <img
                      src="assets/images/KT-admission-red.png"
                      alt="University Admission Consulting"
                      className="static-image"
                      loading="lazy"
                      decoding="async"
                    />
                    <img
                      src="assets/images/KT-admission.gif"
                      alt="University Admission Consulting Animated"
                      className="animated-image"
                      loading="lazy"
                      decoding="async"
                    />
                  </div>
                  <h5>University Admission Consulting</h5>
                  <p>Navigate the university admission process with ease, thanks to our expert guidance.</p>
                  <br />
                  <Link to="/looking-for-admission-service-overseas-chennai" className="explore-button">
                    <span className="explore-texts">Explore More</span>
                    <span className="arrows">&rarr;</span>
                  </Link>
                </div>
              </div>

            </div>
          </div>
        </div>
        <br />
        <br />
      </section>

      <Footer />
    </>
  );
};

export default Services;

{/* <a href="https://www.flaticon.com/free-animated-icons/computer" title="computer animated icons">Computer animated icons created by Freepik - Flaticon</a>web 
              <a href="https://www.flaticon.com/free-animated-icons/metadata" title="metadata animated icons">Metadata animated icons created by Freepik - Flaticon</a>soft
              <a href="https://www.flaticon.com/free-animated-icons/article" title="article animated icons">Article animated icons created by Freepik - Flaticon</a>content
              <a href="https://www.flaticon.com/free-animated-icons/content-marketing" title="content marketing animated icons">Content marketing animated icons created by Freepik - Flaticon</a>digital
              <a href="https://www.flaticon.com/free-icons/domain-registration" title="domain registration icons">Domain registration icons created by Freepik - Flaticon</a> domain
              <a href="https://www.flaticon.com/free-animated-icons/cloud-computing" title="cloud computing animated icons">Cloud computing animated icons created by Freepik - Flaticon</a>hosting
              <a href="https://www.flaticon.com/free-animated-icons/seo" title="seo animated icons">Seo animated icons created by Freepik - Flaticon</a>seo
              <a href="https://www.flaticon.com/free-animated-icons/university" title="university animated icons">University animated icons created by Freepik - Flaticon</a>admission
              <a href="https://www.flaticon.com/free-animated-icons/write" title="write animated icons">Write animated icons created by Freepik - Flaticon</a> sop
              academic<a href="https://www.flaticon.com/free-animated-icons/university-book" title="university book animated icons">University book animated icons created by Freepik - Flaticon</a>
              */}