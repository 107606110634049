import React, { Fragment, useEffect } from 'react'
import Header from './Header';
import Footer from './Footer';
const ContactUs = () => {
  useEffect(() => {
    document.title = 'Contact Us | KumbhaaTech Solutions';
    return () => {
      document.title = 'leading software company - KumbhaaTech Solutions';
    };
  }, []);
  return (
    <Fragment>
      <Header />
      <div class="custom-banner">
        <div class="custom-banner-content">
          <h1>Connect with KumbhaTech</h1>
          <p>Whether you have specific questions about our services or are eager to delve into the details of your project, our team is dedicated to providing the support and expertise you need. Reach out to us today to start a conversation about how we can collaborate and achieve your goals.</p>
          <br />

        </div>
      </div>

      <section className="contact-us" id="contact">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 align-self-center">
              <div className="row">
                <div className="col-lg-12">
                  <form id="contact" action="" method="post">
                    <div className="row">
                      <div className="col-lg-12">
                        <h2>Let's get in touch</h2>
                      </div>
                      <div className="col-lg-4">
                        <fieldset>
                          <input name="name" type="text" id="name" placeholder="YOURNAME...*" required="" />
                        </fieldset>
                      </div>
                      <div className="col-lg-4">
                        <fieldset>
                          <input name="email" type="text" id="email" pattern="[^ @]*@[^ @]*" placeholder="YOUR EMAIL..." required="" />
                        </fieldset>
                      </div>
                      <div className="col-lg-4">
                        <fieldset>
                          <input name="subject" type="text" id="subject" placeholder="SUBJECT...*" required="" />
                        </fieldset>
                      </div>
                      <div className="col-lg-12">
                        <fieldset>
                          <textarea name="message" type="text" className="form-control" id="message" placeholder="YOUR MESSAGE..." required=""></textarea>
                        </fieldset>
                      </div>
                      <div className="col-lg-12">
                        <fieldset>
                          <button type="submit" id="form-submit" className="button">SEND MESSAGE NOW</button>
                        </fieldset>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="right-info">
                <ul>
                  <li>
                    <h6>Phone Number</h6>
                    <span>+91-7880002227</span>
                  </li>
                  <li>
                    <h6>Email Address</h6>
                    <span>support@kumbhatech.com</span>
                  </li>
                  <li>
                    <h6>Street Address</h6>
                    <span>Rio de Janeiro - RJ, 22795-008, Brazil</span>
                  </li>
                  <li>
                    <h6>Website URL</h6>
                    <span>www.meeting.edu</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>


      </section>
      <br />
      <Footer />
    </Fragment>
  )
}

export default ContactUs;
