import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Components/Home';
import AboutUs from './Components/AboutUs';
import ContactUs from './Components/ContactUs'
import Services from './Components/Services';
import Project from './Components/Projects';
import WebDevelopment from './Components/OurServices/WebDevelopment';
import ContentWriting from './Components/ContentWritingService/ContentWriting'
import SoftwareDevelopment from './Components/SoftwareDevelopmentService/SoftwareDevelopment';
import DigitalMarketing from './Components/DigitalMarketingService/DigitalMarketing';
import DomineRegistration from './Components/DomineRegistration/DomineRegistration';
import SeoService from './Components/SeoService/SeoService';
import Blog from './Components/Blog';
import AcademicWriting from './Components/AcademicWriting/AcademicWriting'
import HowWeWork from './Components/HowWeWork';
import Careers from './Components/Career';
import SopWriting from './Components/SopWriting/SopWriting';
import AdmissionService from './Components/AdminssionService/AdmissonService';
import WebHosting from './Components/WebHosting/WebHosting';

import Telemarketing from './Components/telemarketing/Telemarketing';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/services' element={<Services />} />
          <Route path='/kumbhaatech-affordabe-software-development-chennai' element={<Project />} />
          <Route path='/kumbhatech-affordable-website-design-chennai' element={<ContactUs />} />
          <Route path='/affordable-website-design-services-chennai' element={<WebDevelopment />} />
          <Route path='/cheap-PHP-MYSQL-software-customization-chennai' element={<SoftwareDevelopment />} />
          <Route path='/cheap-content-writing-seo-chennai' element={<ContentWriting />} />
          <Route path='/affordable-strategic-digital-marketing-chennai' element={<DigitalMarketing />} />
          <Route path='/cheap-domain-registration-chennai' element={<DomineRegistration />} />
          <Route path='/affordable-seo-site-ranking-service-chennai' element={<SeoService />} />
          <Route path='/affordable-MBA-academic-writing-service-affordable-chennai' element={<AcademicWriting />} />
          <Route path='/how-we-works' element={<HowWeWork />} />
          <Route path='/cheap-overseas-sop-writing-college-universities-visa-chennai' element={<SopWriting />} />
          <Route path='/looking-for-admission-service-overseas-chennai' element={<AdmissionService />} />
          <Route path='/web-hosting-services' element={<WebHosting />} />
          <Route path='/kumbhaatech-affordabe-software-development-chennai' element={<Blog />} />
          <Route path='/career' element={<Careers />} />
          <Route path='/looking-for-affordable-telemarketing-service-chennai' element={<Telemarketing/>} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
