import React from 'react';
import AccordionItem from './AccordionItem';

const Accordion = () => {
  return (
    <div className="col-lg-6">
      <div className="accordions is-first-expanded">
        <AccordionItem
          title="Affordable Excellence: Budget Friendly Solutions for High Quality Digital Services."
          content="We believe that every business deserves a professional online presence, regardless of their budget. We offer a range of cheap website development packages to suit your needs and budget, ensuring you get the most value for your investment."
        />
        <AccordionItem
          title="Elevate Your Brand with Kumbhaatech Solutions: High Quality Design Services That Inspire and Engage."
          content="Don't let the low cost web design fool you. Our team of experienced designers creates visually appealing and user friendly websites that effectively represent your brand and resonate with your target audience."
        />

        {/*  <AccordionItem
          title="Seamless User Experience: Kumbhaatech Solutions Offers Responsive Layouts for Every Device."
          content="Seamless user experience on all devices."
        /> */}
        <AccordionItem
          title="Prioritizing Performance: Focus on Functionality for Optimal User Experience and Efficient Operations."
          content=" We understand that a website needs to be more than just visually appealing. We ensure your website is inexpensive yet functional, featuring user-friendly navigation, fast loading times, and mobile responsiveness to deliver a seamless user experience across all devices."
        />
        <AccordionItem
          title="Maximize Your Online Visibility: Comprehensive SEO Optimization for Higher Rankings and Increased Traffic."
          content="We integrate affordable Search Engine Optimization (SEO) best practices into your website development, helping you rank higher in search engine results pages (SERPs) and attract organic traffic."
        />

        <AccordionItem
          title="Ensuring Transparent Communication for Clear and Trustworthy Client Partnerships."
          content="We believe in clear and open communication throughout the entire process. We'll work closely with you to understand your vision, preferences, and budget, keeping you informed every step of the way."
        />


      </div>
    </div>
  );
};

export default Accordion;
